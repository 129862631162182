<nz-modal
    [nzTitle]="wts.getI18nLabel(i18nBasePath, 'title')"
    [nzAutofocus]="null"
    [nzClosable]="true"
    [nzFooter]="null"
    [nzVisible]="true"
    nzWrapClassName="payment-funds-modal payment-funds-modal-add"
    (nzOnCancel)="handleAddFundsCancel.emit()"
    #addFundsModalEl>
    <ng-container *nzModalContent>
        <div
            appLinkify
            [linkConfigPath]="i18nBasePath + '.linkConfigs'">
            <app-add-funds-form
                #addFundsFormEl
                [addFundsForm]="addFundsForm"
                [addFundsError]="addFundsError"></app-add-funds-form>

            <div *ngIf="dwollaDefaultCashInAccount">
                <div
                    class="payment-funds__heading"
                    [innerHTML]="wts.getI18nLabel(i18nBasePath, 'cashIn.heading') | sanitizeHtml"></div>

                <button
                    class="payment-btn payment-btn-inactive payment-btn-details"
                    nz-button
                    nzBlock>
                    <div class="payment-btn__row">
                        <div
                            class="payment-btn__title"
                            [innerHTML]="dwollaDefaultCashInAccount?.result?.accountName | sanitizeHtml"></div>
                        <div
                            class="payment-btn__descr"
                            [innerHTML]="dwollaDefaultCashInAccount?.result?.accountType | sanitizeHtml"></div>
                    </div>
                    <div class="payment-arrow">
                        <a
                            nz-button
                            (click)="handleEditCashInAccount.emit()">
                            {{ wts.getI18nLabel(i18nBasePath, 'cashIn.actions.edit') }}
                        </a>
                    </div>
                </button>

                <button
                    class="payment-funds__submit"
                    nz-button
                    nzBlock
                    nzSize="large"
                    nzType="primary"
                    [disabled]="bazaFormValidatorService.isFormFilledAndValid(addFundsForm)"
                    (click)="onFormSubmit(addFundsModalEl)">
                    {{ wts.getI18nLabel(i18nBasePath, 'actions.transfer') }}
                </button>
            </div>
        </div>
    </ng-container>
</nz-modal>
