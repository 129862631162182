<nz-modal
    nzClassName="ant-modal-sm"
    [nzTitle]="titleTpl"
    [nzAutofocus]="null"
    [nzClosable]="false"
    [nzFooter]="footerTpl"
    [nzVisible]="true">
    <div *nzModalContent>
        <nz-form-item *ngIf="message$ | async as message">
            <nz-alert
                class="ant-alert-block"
                [nzMessage]="message.text"
                [nzType]="message.type"
                data-cy="additional-file__error"></nz-alert>
        </nz-form-item>

        <p
            class="ant-modal__descr"
            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'descr') | sanitizeHtml"></p>

        <div class="ant-form-inverse">
            <nz-upload
                nzAccept=".png, .jpg, .jpeg, .pdf"
                [nzBeforeUpload]="onFileUpload"
                [(nzFileList)]="fileList"
                data-cy="additional-file__input">
                <button
                    nz-button
                    nzType="default"
                    data-cy="additional-file__upload">
                    {{ wts.getI18nLabel(i18nBasePath, 'actions.btnUpload.label') }}

                    <span *ngIf="fileList?.length > 0">&nbsp; {{ wts.getI18nLabel(i18nBasePath, 'actions.btnUpload.hint') }}</span>
                </button>
            </nz-upload>
        </div>
    </div>

    <ng-template #titleTpl>
        <div
            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'title') | sanitizeHtml"
            data-cy="additional-file__title"></div>
    </ng-template>

    <ng-template #footerTpl>
        <button
            nz-button
            nzBlock
            nzSize="large"
            nzType="primary"
            [disabled]="!fileList || fileList?.length === 0"
            (click)="onFormSubmit()"
            data-cy="additional-file__submit"
            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'actions.btnConfirm.label') | sanitizeHtml"></button>
    </ng-template>
</nz-modal>
