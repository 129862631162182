import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy } from '@angular/core';
import { BazaLinkUtilSharedService } from '../../services';
import { LinkConfig } from '../../models';
import { WINDOW } from '@ng-web-apis/common';

@Directive({
    selector: '[appLinkify]',
})
export class I18nLinkifyDirective implements AfterViewInit, OnDestroy {
    @Input()
    linkConfigPath: string;

    // Reference article: https://medium.com/ngconf/reapplying-an-angular-directive-on-dom-changes-6cce7c390896
    private observer = new MutationObserver(() => this.handleDynamicLinks());
    private processing = false;

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly uts: BazaLinkUtilSharedService,
        @Inject(WINDOW) readonly windowRef: Window,
    ) {}

    ngAfterViewInit() {
        this.handleDynamicLinks();
        this.registerListenerForDomChanges();
    }

    ngOnDestroy() {
        this.observer.disconnect();
    }

    handleDynamicLinks() {
        setTimeout(() => {
            if (!this.processing) {
                this.processing = true;
                const linkConfigs: Array<LinkConfig> = this.uts.getI18nLinkConfigs(this.linkConfigPath);

                if (!linkConfigs?.length || (linkConfigs.length === 1 && !linkConfigs[0].key)) return;

                linkConfigs?.forEach((linkConfig: LinkConfig) => {
                    if (linkConfig?.key) {
                        const linkNodes = this.windowRef?.document?.querySelectorAll(
                            '[data-link="' + linkConfig.key + '"]',
                        ) as NodeListOf<Element>;
                        if (!linkNodes.length) return;

                        linkNodes?.forEach((linkNode) => {
                            if (!linkNode || linkNode.classList.contains('linkify')) return;

                            linkNode.className = 'linkify';
                            linkNode.textContent = this.uts.getUrlText(linkConfig);

                            linkNode.addEventListener('click', () => {
                                this.uts.navigate(linkConfig);
                            });
                        });
                    }
                });
                this.processing = false;
            }
        });
    }

    private registerListenerForDomChanges() {
        const attributes = false;
        const childList = true;
        const subtree = true;
        this.observer.observe(this.element?.nativeElement, { attributes, childList, subtree });
    }
}
