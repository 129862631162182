<ng-container
    *ngIf="
        {
            purchaseStart: purchaseStart$ | async,
            entity: cart$ | async
        } as data;
        else skeleton
    ">
    <div
        class="purchase-agrmt"
        appLinkify
        [linkConfigPath]="i18nBasePath + '.linkConfigs'">
        <div class="steps-content">
            <div
                nz-row
                nzGutter="30"
                class="purchase__row">
                <div
                    nz-col
                    nzXs="12"
                    class="purchase__info">
                    <div
                        class="purchase__heading"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'title') | sanitizeHtml"></div>

                    <ng-container *ngIf="!data.purchaseStart?.areDocumentsSigned">
                        <div class="purchase__descr">
                            <div
                                class="purchase__disclaimer"
                                [innerHTML]="wts.getI18nLabel(i18nBasePath, 'messages.disclaimer') | sanitizeHtml"></div>

                            <div class="purchase__divider"></div>

                            <div
                                class="purchase__consent"
                                [innerHTML]="wts.getI18nLabel(i18nBasePath, 'messages.pdfConsent') | sanitizeHtml"></div>
                        </div>

                        <button
                            class="purchase-agrmt__btn"
                            nz-button
                            nzType="primary"
                            (click)="openAgreementModal(data.entity, data.purchaseStart)">
                            <span>{{ wts.getI18nLabel(i18nBasePath, 'actions.sign') }}</span>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="data.purchaseStart?.areDocumentsSigned">
                        <p
                            class="purchase__descr"
                            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'messages.success') | sanitizeHtml"></p>
                    </ng-container>
                </div>

                <div
                    nz-col
                    nzXs="12"
                    class="purchase__summary">
                    <app-summary
                        [entity]="cart$ | async"
                        [numberOfShares]="numberOfShares$ | async"
                        [purchaseStart]="purchaseStart$ | async"></app-summary>
                </div>
            </div>
        </div>

        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="purchase__info">
                <div class="steps-action">
                    <button
                        class="btn-angle-right"
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        type="submit"
                        [disabled]="!data.purchaseStart?.areDocumentsSigned"
                        (click)="onFormSubmit()"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'actions.next') | sanitizeHtml"></button>

                    <a
                        nz-button
                        nzSize="large"
                        nzType="default"
                        (click)="onBackClick(data.purchaseStart)"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'actions.back') | sanitizeHtml"></a>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
